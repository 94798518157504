<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlZbglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :receiveTreedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" :hasIcon="true" :incomeTreeData="true" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" @tableColumnClick="tableColumnClick"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>
    

    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 编辑弹窗 -->
    <general-form :FormDialog="FormDialogEdit" :FormList="FormList" :title="editTitle" :FormData="modifyForm"  @cancelDialog="cancelDialogEdit" @confirmDialog="confirmDialogEdit"></general-form>

    <!-- 区域调整 -->
    <general-form :FormDialog="areaChangeDialog" :FormList="areaChangeList" :title="'区域调整'" :FormData="areaChangeForm" @cancelDialog="cancelAreaChange" @confirmDialog="confirmAreaChange" :isSpanAll="true" :isAreaTree="true" :chooseTotalMemterAreaData="mainWaterMeter"></general-form>

  </div>

</template>

<script>
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import {listToTreeSelect} from '@/utils'//树结构转换

  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          key: ''
        },
        orgs: [], // 用户可访问到的组织列表
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{ },//添加表单
        modifyForm:{},//编辑表单
        FormList:{//表格列
          column:[
            {columnKey:'concentratorName',columnDescription:'集中器名称',columnType:'text',prop:'concentratorName',width:'120',placeholder:"集中器名称",},
            {columnKey:'concentratorAddress',columnDescription:'集中器地址',columnType:'text',prop:'concentratorAddress',width:'120',placeholder:"集中器地址",},
            {columnKey:'belongsArea',columnDescription:'所属区域',columnType:'text',prop:'belongsArea',width:'120',placeholder:"所属区域",},
            {columnKey:'installAddress',columnDescription:'安装地址',columnType:'text',prop:'installAddress',width:'120',placeholder:"安装地址",},
            {columnKey:'communicationProtocol',columnDescription:'通讯规约',columnType:'text',prop:'communicationProtocol',width:'120',placeholder:"通讯规约",},
            {columnKey:'programmePlatform',columnDescription:'方案所属平台',columnType:'text',prop:'programmePlatform',width:'120',placeholder:"方案所属平台",},
            // {columnKey:'project',columnDescription:'项目',columnType:'text',prop:'project',width:'120',placeholder:"项目",},
          ],
          //校验
          rule:{
            concentratorName: [{ required: true, message: "集中器名称不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        FormDialogEdit:false,//编辑弹窗
        editTitle:'修改',//编辑弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        mainMultipleSelection:[],//多选数据
        orgsTree:[],//左侧总表数
        mainWaterMeter:[],//选中的总表
        areaChangeDialog:false,//区域调整弹窗
        areaChangeList:{//区域调整表格列
          column:[
            {
              columnKey:'areaId',
              columnDescription:'区域',
              columnType:'selectAreaTree',
              prop:'areaId',
              width:'120',
              placeholder:"区域",
            },
          ],
          //校验
          rule:{
            area: [{ required: true, message: "区域不能为空", trigger: "blur" }, ],
          }
        },
        areaChangeForm:{},//区域调整表单
      }
    },
    watch: {

    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      this.getTree()
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      //获取树
      getTree(){
        basicDataApi.zbglGetList().then(response => {
          if(response.code == 200){
            var s  = response.data
            var Process = s.map(function(item) {
              return {
                id: item.id,
                label: item.areaName,
                areaNum: item.areaNum,
                parentId: item.fatherNode || null
              }
            })
            var orgstmp = JSON.parse(JSON.stringify(Process))
            this.orgsTree = listToTreeSelect(orgstmp)
          }
        }).catch(()=>{
          this.orgsTree = []
        })
      },
      // 节点点击
      handleNodeClick(ids,data) {
        // console.log(data)
        this.mainWaterMeter = data//选中总表赋值
        this.listQuery.TreeIds = ids
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          // case 'btnDel'://删除
          //   if(this.tableChooseRow.id){
          //     this.handleDelete(this.tableChooseRow)
          //   }else{
          //     this.$message({
          //       message: `请选择一条数据进行修改！`,
          //       type: 'error'
          //     })
          //   }
          //   break
          // case 'btnAdd'://添加
          //   this.FormDialog = true
          //   break
          // case 'btnEdit'://编辑
          //   if(this.tableChooseRow.id){
          //     this.modify(this.tableChooseRow)
          //   }else{
          //     this.$message({
          //       message: `请选择一条数据进行修改！`,
          //       type: 'error'
          //     })
          //   }
          //   break
          case 'btnBind'://子表绑定总表
            if(this.listQuery.TreeIds.length != 1){
              this.$message({
                message: `请选择一个需要绑定的总表！`,
                type: 'error'
              })
              return
            }
            if(this.mainWaterMeter.areaNum!= "▩"){
              this.$message({
                message: `请选择一个总表！`,
                type: 'error'
              })
              return
            }
            if(this.mainMultipleSelection.length <= 0){
              this.$message({
                message: `请选择需要绑定的分表！`,
                type: 'error'
              })
              return
            }
            this.bind()
            break
          case 'btnChange'://总表更换区域
            if(this.listQuery.TreeIds.length != 1){
              this.$message({
                message: `请选择一个需要更换的总表！`,
                type: 'error'
              })
              return
            }
            if(this.mainWaterMeter.areaNum != "▩"){
              this.$message({
                message: `请选择一个总表！`,
                type: 'error'
              })
              return
            }
            this.areaChangeDialog = true
            break
            
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.zbglGetChildrenMeterList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              if(i.key == 'surfaceNum'){
                i.columnWidth = 160
              }
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.$confirm(`确定要删除集中器名称为${row.concentratorName}的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = [row.id]
          basicDataApi.jzqglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.reset()//重置
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
         basicDataApi.jzqglAdd(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialog = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialog = false
        })
        this.reset()//重置
      },
      //重置
      reset(){
        this.addform = this.modifyForm ={
          concentratorName:'',
          concentratorAddress:'',
          belongsArea:'',
          installAddress:'',
          communicationProtocol:'',
          programmePlatform:'',
          project:'',
        }
        this.tableChooseRow = {}
      },
      // 编辑表
      modify(row){
        this.modifyForm = JSON.parse(JSON.stringify(row))
        this.FormDialogEdit = true
      },
      // 编辑弹窗取消
      cancelDialogEdit(val){
        this.FormDialogEdit = val
      },
      // 编辑弹窗提交
      confirmDialogEdit(){
        basicDataApi.jzqglModify(this.modifyForm).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialogEdit = false
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogEdit = false
        })
      },

      //表格多选
      handleSelectionChange(val){
        this.mainMultipleSelection = val
      },
      // 绑定分表
      bind(){
        this.$confirm(`确定要把选中的设备绑定总表吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var ids = []
          this.mainMultipleSelection.forEach(i=>{
            ids.push(i.meterId)
          })
          var data={
            mIds:ids,
            sId:this.mainWaterMeter.id,
          }
          basicDataApi.zbglBindChildren(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              setTimeout(() => {
                this.listQuery.page = 1
                this.getList()
              }, 1000);
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          }).catch(()=>{
          })
        }).catch(() => {});
      },
      // 区域调整取消
      cancelAreaChange(val){
        this.areaChangeDialog = val
      },
      // 总表更换区域
      confirmAreaChange(){
         var data={
          areaId:this.areaChangeForm.areaId,
          sId:this.mainWaterMeter.id,//总表id
        }
        basicDataApi.zbglChangeArea(data).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.areaChangeDialog = false
            this.listQuery.TreeIds = []
            this.getList()
            this.getTree()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          
        })
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
